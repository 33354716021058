import { CardActionArea, CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    width: "100%",
    margin: "0px auto",
    background: "#fff",
    padding: "15px",
  },

  cover: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },

  cardMedia: {
    height: "150px",
    width: "100%",
    objectFit: "cover",
  },
  informationContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#00D7B8",
    padding: "20px 0px",
    width: "100%",
  },
  countryText: {
    color: "#fff",
    marginTop: "10px ",
  },
  lessPadding: {
    padding: "0px",
  },
  media: {
    height: 160,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

function CountryCard({ country }) {
  const classes = useStyles();
  const history = useHistory();
  const push = history.push;

  let imgSrc = "";

  if (country.description) {
    const found = country.description.match(/src\s*=\s*"(.+?)"/g);

    if (found && found.length > 0) {
      imgSrc = found[0].replace(`src="`, "").trim();
      imgSrc = imgSrc.substring(0, imgSrc.length - 1);
    }
  }

  return (
    <React.Fragment>
      <Card
        square={true}
        elevation={2}
        className={classes.root}
        onClick={() => push(`/locations/${country.slug}`)}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={imgSrc.replace("http://", "https://")}
            alt={country.name}
            title={country.name}
          />

          <CardContent className={classes.lessPadding}>
            <div className={classes.informationContainer}>
              <LocationOnIcon color="primary" />
              <Typography
                variant="body1"
                component="h2"
                className={classes.countryText}
              >
                {country.name}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}

export default CountryCard;
