import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
    marginBottom: "20px",
    width: "100%",
    boxShadow: "none",
    height: "15vw",
    minHeight: "150px",
    marginBottom: "0px",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
  },

  cover: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  title1: {
    color: theme.palette.background.paper,
    fontWeight: 500,
  },

  title2: {
    color: theme.palette.background.paper,
    fontWeight: "bolder",
    marginBottom: "10px",
  },
  content: {
    color: theme.palette.background.paper,
  },
}));

function HomeNavigationCard({
  title1,
  title2,
  // coverImage,
  contentText,

  backgroundColor,
  textColor,
  to,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const push = history.push;

  return (
    <React.Fragment>
      <Card
        square={true}
        style={{ background: backgroundColor }}
        className={classes.root}
        onClick={() => push(to)}
      >
        <div className={classes.details}>
          <CardContent>
            <Typography className={classes.title1} component="h5" variant="h4">
              {title1}
            </Typography>
            <Typography className={classes.title2} component="h5" variant="h4">
              {title2}
            </Typography>
            <Typography
              className={classes.content}
              component="h5"
              variant="h5"
              style={{ color: textColor }}
            >
              {contentText}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default HomeNavigationCard;
