import { useMediaQuery } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  createMuiTheme,
  fade,
  makeStyles,
  responsiveFontSizes,
  useTheme,
} from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EventSeat from "@material-ui/icons/ContactPhone";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import StarsIcon from "@material-ui/icons/Stars";
import WarningIcon from "@material-ui/icons/Warning";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import * as types from "./constants/ActionTypes";
import Countries from "./containers/Countries";
import DeskConsult from "./containers/DeskConsult";
import EmergencyContacts from "./containers/EmergencyContacts";
import Favorites from "./containers/Favorites";
import Home from "./containers/Home";
import OfficeDetail from "./containers/OfficeDetail";
import OurOffices from "./containers/OurOffices";
import OurPeople from "./containers/OurPeople";
import PeopleDetail from "./containers/PeopleDetail";
//

let muiBaseTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#27008C",
    },
    secondary: {
      main: "#00D8B8",
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: "'Verdana',Helvetica,Arial,Lucida,sans-serif",
    h2: {
      fontFamily: "'Georgia',Georgia,'Times New Roman',serif",
    },
    h6: {
      fontFamily: "'Georgia',Georgia,'Times New Roman',serif",
    },
    h4: {
      fontFamily: "'Georgia',Georgia,'Times New Roman',serif",
    },
    h5: {
      fontFamily: "'Georgia',Georgia,'Times New Roman',serif",
    },
    body1: {
      fontFamily: "'Georgia',Georgia,'Times New Roman',serif",
    },
  },
});

muiBaseTheme = responsiveFontSizes(muiBaseTheme);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarWhite: {
    background: theme.palette.background.paper,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    background: theme.palette.background.paper,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
}));

function shouldFetchEmployees(employees) {
  if (!employees) {
    return true;
  }
  if (employees.isFetching) {
    return false;
  }
  return true;
}

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const push = history.push;
  const employees = useSelector((state) => state.employees);
  const favorites = useSelector((state) => state.favorites);
  const [open, setOpen] = useState(false);

  const tooSmallPhone = useMediaQuery("(max-width:380px)");

  useEffect(() => {
    async function fetchData() {
      batch(() => {
        dispatch({
          type: types.REQUEST_EMPLOYEES,
        });
        dispatch({
          type: types.REQUEST_COUNTRIES,
        });
        dispatch({
          type: types.REQUEST_OFFICES,
        });
        dispatch({
          type: types.REQUEST_REGIONS,
        });
        dispatch({
          type: types.REQUEST_CONTACTS,
        });
      });

      const response = await axios.get(
        "https://y3hznmutmd.execute-api.eu-west-1.amazonaws.com/prod/data",
        {
          headers: {
            "x-api-key": "GD8hVO2QptayevaLI4B2M2V5YPSZM2eP18PmnK39",
          },
        }
      );
      batch(() => {
        dispatch({
          type: types.RECEIVE_EMPLOYEES,
          receivedAt: Date.now(),
          employees: response.data.employees,
        });
        dispatch({
          type: types.RECEIVE_COUNTRIES,
          receivedAt: Date.now(),
          countries: response.data.countries,
        });
        dispatch({
          type: types.RECEIVE_OFFICES,
          receivedAt: Date.now(),
          offices: response.data.offices,
        });
        dispatch({
          type: types.RECEIVE_REGIONS,
          receivedAt: Date.now(),
          regions: response.data.regions,
        });
        dispatch({
          type: types.RECEIVE_CONTACTS,
          receivedAt: Date.now(),
          contacts: response.data.contacts,
        });
      });
    }

    if (shouldFetchEmployees(employees)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let hasFavorites = favorites.length > 0;

  return (
    <React.Fragment>
      <ThemeProvider theme={muiBaseTheme}>
        <div className={classes.root}>
          <CssBaseline />
          <NavigationBar
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Hidden smDown>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button onClick={() => push("/")}>
                  <ListItemIcon>
                    <HomeIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItem>

                <ListItem button onClick={() => push("/our-people")}>
                  <ListItemIcon>
                    <AccountCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={"Our People"} />
                </ListItem>

                <ListItem button onClick={() => push("/locations")}>
                  <ListItemIcon>
                    <LocationOnIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={"Our Offices"} />
                </ListItem>

                <ListItem button onClick={() => push("/emergency-contacts")}>
                  <ListItemIcon>
                    <WarningIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={"Emergency Contacts"} />
                </ListItem>

                <ListItem button onClick={() => push("/desk-consult")}>
                  <ListItemIcon>
                    <EventSeat color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={"Desk Consult"} />
                </ListItem>

                {hasFavorites && (
                  <ListItem button onClick={() => push("/favorites")}>
                    <ListItemIcon>
                      <StarsIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Favorites"} />
                  </ListItem>
                )}
              </List>
            </Drawer>
          </Hidden>
          <Box width="100%" paddingBottom="56px">
            <Switch>
              <Route path="/test">
                <React.Fragment>test </React.Fragment>
              </Route>
              <Route path="/our-people/:personId">
                <PeopleDetail />
              </Route>
              <Route path="/favorites/:personId">
                <PeopleDetail />
              </Route>
              <Route path="/our-people">
                <OurPeople />
              </Route>
              <Route path="/locations/:countryId">
                <OurOffices />
              </Route>
              <Route path="/our-offices/:officeId">
                <OfficeDetail />
              </Route>
              <Route path="/locations">
                <Countries />
              </Route>
              <Route path="/emergency-contacts">
                <EmergencyContacts />
              </Route>
              <Route path="/desk-consult">
                <DeskConsult />
              </Route>
              <Route path="/favorites">
                <Favorites />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Box>
          <Hidden only={["lg", "md", "xl"]}>
            <Box position="fixed" width="100%" bottom={0}>
              <BottomNavigation
                onChange={(event, newValue) => {
                  switch (newValue) {
                    case 0:
                      push("/");
                      break;
                    case 1:
                      push("/our-people");
                      break;
                    case 2:
                      push("/locations");
                      break;
                    case 3:
                      push("/emergency-contacts");
                      break;
                    case 4:
                      push("/favorites");
                      break;
                    default:
                      push("/");
                  }
                }}
                className={classes.root}
                showLabels={false}
              >
                <BottomNavigationAction
                  style={{ display: "block" }}
                  label="Home"
                  showLabel={false}
                  icon={<HomeIcon color="secondary" />}
                />
                <BottomNavigationAction
                  style={{ display: "block" }}
                  label="Our People"
                  showLabel={false}
                  icon={<AccountCircleIcon color="secondary" />}
                />
                <BottomNavigationAction
                  style={{ display: "block" }}
                  label="Our Offices"
                  showLabel={false}
                  icon={<LocationOnIcon color="secondary" />}
                />

                {tooSmallPhone === false && (
                  <BottomNavigationAction
                    style={{ display: "block" }}
                    label="Emergency Contacts"
                    showLabel={false}
                    icon={<WarningIcon color="secondary" />}
                  />
                )}

                {hasFavorites && (
                  <BottomNavigationAction
                    style={{ display: "block" }}
                    label="Favorites"
                    showLabel={false}
                    icon={<StarsIcon color="secondary" />}
                  />
                )}
              </BottomNavigation>
            </Box>
            <pwa-update swpath={"/service-worker.js"}></pwa-update>
          </Hidden>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
