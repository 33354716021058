import { Box, CardMedia, CircularProgress, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import yellow from "@material-ui/core/colors/yellow";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import _find from "lodash/find";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import * as types from "../constants/ActionTypes";

let backgroundArray = ["#00d8b9", "#250188", "#DBDBDB"];

const selectEmployeeByPersonId = createSelector(
  (state) => state.employees,
  (_, personId) => personId,
  (employees, personId) => _find(employees.items, { slug: personId })
);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
  },
  cardMedia: {
    maxHeight: "255px",
  },
  contactSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    marginTop: "10px",
  },
  Accordion: {
    backgroundColor: "#250188",
  },
  NoShadow: {
    boxShadow: "none",
  },
  noBackground: {
    backgroundColor: "#fafafa",
    paddingTop: "0px",
  },
  MoreSlim: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    padding: "25px",
    width: "100%",
  },
  propValue: {
    marginBottom: "25px",
  },
  favoriteButton: {
    position: "absolute",
    bottom: "5px",
    left: "5px",
  },
  media: {
    height: "100%",
    width: "100%",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    minHeight: "180px",
  },
}));

function PeopleDetail() {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites);
  const history = useHistory();
  const push = history.push;
  let { personId } = useParams();

  let location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const employee = useSelector((state) =>
    selectEmployeeByPersonId(state, personId)
  );

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!employee) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  let isFavorite = favorites.indexOf(employee.id) > -1;

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} sm={6} md={6}>
          <Card square={true}>
            <CardContent className={classes.contactSection}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    height="100%"
                    display="flex"
                    alignItems={"flex-end"}
                    justifyContent="center"
                    style={{
                      backgroundColor:
                        backgroundArray[
                          location &&
                          location.state &&
                          location.state.backgroundIndex
                            ? location.state.backgroundIndex
                            : 0
                        ],
                    }}
                  >
                    <CardMedia
                      className={classes.media}
                      image={
                        employee.background_image.url
                          ? employee.background_image.url.replace(
                              "http://",
                              "https://"
                            )
                          : ""
                      }
                      alt={employee.background_image.alt}
                      title={employee.background_image.title}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="primary"
                      component="h2"
                      style={{ fontWeight: "700" }}
                    >
                      {`${employee.first_name} ${employee.last_name}`}
                    </Typography>
                    <Typography variant="body2" color="primary" component="h2">
                      {employee.function}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      href={"mailto:" + employee.email}
                      startIcon={<EmailIcon color="secondary" />}
                    >
                      Email
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<PhoneIcon color="secondary" />}
                      href={"tel:" + employee.telefoonnummer}
                    >
                      <Hidden only="sm">{employee.telefoonnummer}</Hidden>
                      <Hidden only={["xs", "md", "lg", "xl"]}>Call</Hidden>
                    </Button>

                    <Tooltip
                      title={
                        employee.officeNames.length > 0
                          ? employee.officeNames[0]
                          : ""
                      }
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={employee.officeNames.length === 0}
                        onClick={(e) => {
                          if (employee.main_office) {
                            push(`/our-offices/${employee.main_office}`);
                          } else if (
                            employee["office-app"] &&
                            employee["office-app"].length > 0
                          ) {
                            push(`/our-offices/${employee["office-app"][0]}`);
                          }
                        }}
                        startIcon={<LocationOnIcon color="secondary" />}
                      >
                        Office
                      </Button>
                    </Tooltip>

                    {isFavorite ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={(e) => {
                          dispatch({
                            id: employee.id,
                            type: types.REMOVE_FAVORITE,
                          });

                          if (location.pathname.includes("/favorites/")) {
                            push("/favorites");
                          }
                        }}
                        startIcon={<Star htmlColor={yellow[500]} />}
                      >
                        unfavorite
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          dispatch({
                            id: employee.id,
                            type: types.ADD_FAVORITE,
                          });
                        }}
                        className={classes.button}
                        startIcon={<StarBorder htmlColor={yellow[500]} />}
                      >
                        favorite
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      href={
                        "https://y3hznmutmd.execute-api.eu-west-1.amazonaws.com/prod/card/" +
                        personId
                      }
                      className={classes.button}
                      startIcon={<AddIcon color="secondary" />}
                    >
                      Add to contacts
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <ExpansionPanel
            className={classes.NoShadow}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.Accordion}
            >
              <Typography color="secondary">Profile details</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.noBackground}>
              <CardContent className={classes.MoreSlim}>
                {employee.spoken_languages &&
                  employee.spoken_languages.length > 0 && (
                    <React.Fragment>
                      <Typography variant="body1" color="primary">
                        Spoken languages
                      </Typography>
                      <Typography variant="body1" className={classes.propValue}>
                        {employee.spoken_languages}
                      </Typography>
                    </React.Fragment>
                  )}

                {employee.surveyor_since && employee.surveyor_since.length > 0 && (
                  <React.Fragment>
                    <Typography variant="body1" color="primary">
                      Experience since
                    </Typography>
                    <Typography variant="body1" className={classes.propValue}>
                      {employee.surveyor_since}
                    </Typography>
                  </React.Fragment>
                )}

                {employee.membership && employee.membership.length > 0 && (
                  <React.Fragment>
                    <Typography variant="body1" color="primary">
                      Membership
                    </Typography>
                    <Typography variant="body1" className={classes.propValue}>
                      {employee.membership}
                    </Typography>
                  </React.Fragment>
                )}

                {employee.degree && employee.degree.length > 0 && (
                  <React.Fragment>
                    <Typography variant="body1" color="primary">
                      Degree
                    </Typography>
                    <Typography variant="body1" className={classes.propValue}>
                      {employee.degree}
                    </Typography>
                  </React.Fragment>
                )}

                {employee.discipline && employee.discipline.length > 0 && (
                  <React.Fragment>
                    <Typography variant="body1" color="primary">
                      Discipline
                    </Typography>
                    <Typography variant="body1" className={classes.propValue}>
                      {employee.discipline && employee.discipline.join(", ")}
                    </Typography>
                  </React.Fragment>
                )}

                {employee.sectors && employee.sectors.length > 0 && (
                  <React.Fragment>
                    <Typography variant="body1" color="primary">
                      Sectors
                    </Typography>
                    <Typography variant="body1" className={classes.propValue}>
                      {employee.sectors && employee.sectors.join(", ")}
                    </Typography>
                  </React.Fragment>
                )}

                {employee.areas_of_expertise &&
                  employee.areas_of_expertise.length > 0 && (
                    <React.Fragment>
                      <Typography variant="body1" color="primary">
                        Area(s) of expertise:
                      </Typography>
                      <Typography variant="body1" className={classes.propValue}>
                        {employee.areas_of_expertise &&
                          employee.areas_of_expertise.join(", ")}
                      </Typography>
                    </React.Fragment>
                  )}
              </CardContent>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.NoShadow}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.Accordion}
            >
              <Typography color="secondary">CV summary</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.noBackground}>
              <Typography variant="body1" className={classes.MoreSlim}>
                {employee.cv_summary}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </main>
  );
}

export default PeopleDetail;
