import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageIcon from "@material-ui/icons/Language";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import _filter from "lodash/filter";
import _find from "lodash/find";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import PeopleCard from "../components/PeopleCard";

const selectOfficeByOfficeId = createSelector(
  (state) => state.offices,
  (_, officeId) => officeId,
  (offices, officeId) => {
    return _find(offices.items, { id: Number(officeId) });
  }
);

const selectEmployeesByOfficeId = createSelector(
  (state) => state,
  (_, officeId) => officeId,
  (state, officeId) => {
    return _filter(state.employees.items, function (o) {
      return o["office-app"].indexOf(officeId) > -1;
    });
  }
);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
  contactSection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  button: {
    marginTop: "10px",
  },
  Accordion: {
    backgroundColor: "#250188",
  },
  NoShadow: {
    boxShadow: "none",
  },
  noBackground: {
    backgroundColor: "#fafafa",
    paddingTop: "0px",

    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  MoreSlim: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    padding: "25px",
    width: "100%",
  },

  Grey: {
    position: "relative",
  },
  InfoContainer: {
    padding: "0px 20px 0px 30px",
    marginTop: "20px",
  },
  Absolute: {
    position: "absolute",
    left: "-30px",
  },
}));

function OfficeDetail() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let { officeId } = useParams();

  const office = useSelector((state) =>
    selectOfficeByOfficeId(state, officeId)
  );
  const employees = useSelector((state) =>
    selectEmployeesByOfficeId(state, officeId)
  );
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!office) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3} justify="center">
        <Grid item>
          <Card square={true}>
            <CardContent className={classes.contactSection}>
              <Typography
                variant="body1"
                color="primary"
                component="h2"
                className={classes.bolder}
              >
                {office.name}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={"mailto:" + office.email}
                startIcon={<EmailIcon color="secondary" />}
              >
                Email
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={"tel:" + office.phone}
                startIcon={<PhoneIcon color="secondary" />}
              >
                Call
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={office.website === ""}
                href={office.website}
                startIcon={<LanguageIcon color="secondary" />}
              >
                Website
              </Button>
            </CardContent>
          </Card>
          <ExpansionPanel
            className={classes.NoShadow}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.Accordion}
            >
              <Typography color="secondary">Office Details</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ padding: matches === true ? "8px 24px 24px" : "0px" }}
              className={classes.noBackground}
            >
              <CardContent className={classes.MoreSlim}>
                <div className={classes.InfoContainer}>
                  <Typography
                    variant="body1"
                    component="h2"
                    className={classes.Grey}
                    style={{ marginBottom: "25px" }}
                  >
                    <LocationOnIcon
                      color="secondary"
                      className={classes.Absolute}
                    />
                    {`${office.adress} ${office.town}, ${office.country}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="h2"
                    className={classes.Grey}
                    style={{ marginBottom: "10px" }}
                  >
                    <PhoneIcon color="secondary" className={classes.Absolute} />
                    <a href={"tel:" + office.phone}>{office.phone}</a>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="h2"
                    className={classes.Grey}
                  >
                    <EmailIcon color="secondary" className={classes.Absolute} />
                    <a href={"mailto:" + office.email}>{office.email}</a>
                  </Typography>
                </div>
              </CardContent>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {employees.map((item, i) => {
          return (
            <Grid key={"people" + i} item xs={12} sm={12} md={4} lg={3} xl={2}>
              <PeopleCard index={i} employee={item} />
            </Grid>
          );
        })}
      </Grid>
    </main>
  );
}

export default OfficeDetail;
