import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import querystring from "querystring";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../hooks/useQuery";

function OurPeopleFilterDialog({ open, handleClose }) {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const push = history.push;

  let currentQueryObject = querystring.parse(
    location.search.startsWith("?")
      ? location.search.substring(1)
      : location.search
  );

  function updateQuery(key, value) {
    currentQueryObject[key] = value;

    push({
      ...location,
      search: querystring.stringify(currentQueryObject),
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="filter-dialog-title"
    >
      <DialogTitle id="filter-dialog-title">Filters</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box margin="10px 0" width="100%">
            <FormControl fullWidth variant="standard">
              <InputLabel id="Discipline-label">Discipline</InputLabel>
              <Select
                labelId="Discipline-label"
                id="Discipline-select-filled"
                value={
                  query.get("discipline") ? query.get("discipline") : "All"
                }
                onChange={(event) => {
                  updateQuery("discipline", event.target.value);
                }}
                native
              >
                <option value="All">All</option>
                <option value="Chemist / Scientist">Chemist / Scientist</option>
                <option value="Civil Engineer">Civil Engineer</option>
                <option value="Engineer">Engineer</option>
                <option value="Legal">Legal</option>
                <option value="Marine Engineer">Marine Engineer</option>
                <option value="(Master) Mariner">(Master) Mariner</option>
                <option value="Naval Architect">Naval Architect</option>
              </Select>
            </FormControl>
          </Box>
          <Box margin="10px 0" width="100%">
            <FormControl fullWidth variant="standard">
              <InputLabel id="Sectors-label">Sectors</InputLabel>
              <Select
                labelId="Sectors-label"
                id="Sectors-select-filled"
                value={query.get("sectors") ? query.get("sectors") : "All"}
                onChange={(event) => {
                  updateQuery("sectors", event.target.value);
                }}
                native
              >
                <option value="All">All</option>
                <option value="Consultancy">Consultancy</option>
                <option value="Liability">Liability</option>
                <option value="Loss prevention">Loss prevention</option>
                <option value="Oil, gas &amp; chemicals">
                  Oil, gas &amp; chemicals
                </option>

                <option value="Railroad transport">Railroad transport</option>
                <option value="Renewables">Renewables</option>
                <option value="Road transport">Road transport</option>
                <option value="Shipping">Shipping</option>
                <option value="Technical">Technical</option>
                <option value="Terminals">Terminals</option>
              </Select>
            </FormControl>
          </Box>
          <Box margin="10px 0" width="100%">
            <FormControl fullWidth variant="standard">
              <InputLabel id="Area-label">Area(s) of expertise</InputLabel>
              <Select
                labelId="Area-label"
                id="Area-select-filled"
                value={
                  query.get("areas-of-expertise")
                    ? query.get("areas-of-expertise")
                    : "All"
                }
                onChange={(event) => {
                  updateQuery("areas-of-expertise", event.target.value);
                }}
                native
              >
                <option value="All">All</option>
                <option value="Arbitration">Arbitration</option>
                <option value="Bunker quality disputes">
                  Bunker quality disputes
                </option>
                <option value="Cars">Cars</option>
                <option value="Casualty (nautical) investigations">
                  Casualty (nautical) investigations
                </option>
                <option value="Claims handler">Claims handler</option>
                <option value="Condition surveys (P&amp;I/ H&amp;M / Pre-purchase/On-off hire)">
                  Condition surveys (P&amp;I/ H&amp;M / Pre-purchase/On-off
                  hire)
                </option>
                <option value="Containerized cargoes">
                  Containerized cargoes
                </option>
                <option value="Draught surveys">Draught surveys</option>
                <option value="Dry bulk (Agri/Minerals)">
                  Dry bulk (Agri/Minerals)
                </option>
                <option value="Hatch cover tightness testing (ultrasonic)">
                  Hatch cover tightness testing (ultrasonic)
                </option>
                <option value="Hull &amp; Machinery">
                  Hull &amp; Machinery
                </option>
                <option value="JH143 Shipyard risk surveys">
                  JH143 Shipyard risk surveys
                </option>
                <option value="Lashing and securing">
                  Lashing and securing
                </option>
                <option value="Liquid bulk (Petro chemicals/Oil/Vegetable oil and liquefied gas)">
                  Liquid bulk (Petro chemicals/Oil/Vegetable oil and liquefied
                  gas)
                </option>
                <option value="Marine Warranty">Marine Warranty</option>
                <option value="Oil spills">Oil spills</option>
                <option vlaue="P&amp;I Correspondent">
                  P&amp;I Correspondent
                </option>
                <option value="Personal injury">Personal injury</option>
                <option value="Pilferage">Pilferage</option>
                <option value="Project / heavy lift cargoes">
                  Project / heavy lift cargoes
                </option>
                <option value="Refrigerated cargoes (Fish/Meat/Perishables)">
                  Refrigerated cargoes (Fish/Meat/Perishables)
                </option>
                <option value="Steel (Coils/Finished products)">
                  Steel (Coils/Finished products)
                </option>
                <option value="Survey coordination">Survey coordination</option>
                <option value="Towing">Towing</option>
                <option value="Yachts">Yachts</option>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OurPeopleFilterDialog;
