import { CardActionArea, CardMedia } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // maxWidth: "300px",
    width: "100%",
    margin: "0px auto",
    background: "#fff",
    padding: "15px",
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
  },

  cover: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  title1: {
    color: theme.palette.background.paper,
    fontWeight: 500,
  },

  title2: {
    color: theme.palette.background.paper,
    fontWeight: "lighter",
  },
  cardMedia: {
    height: "auto",
    width: "100%",
  },
  bolder: {
    fontWeight: 700,
    position: "relative",
  },
  Discipline: {
    margin: "20px 0px 15px",
  },

  lessPadding: {
    padding: "8px",
    height: "100%",
  },
  media: {
    height: 160,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

let backgroundArray = ["#00d8b9", "#250188", "#DBDBDB"];

function PeopleCard({ index, employee = {}, origin = "our-people" }) {
  const classes = useStyles();
  const history = useHistory();
  const push = history.push;

  return (
    <React.Fragment>
      <Card
        elevation={2}
        className={classes.root}
        square={true}
        onClick={() =>
          push({
            pathname: `/${origin}/${employee.slug}`,
            state: {
              backgroundIndex: index % 3,
            },
          })
        }
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            style={{ backgroundColor: backgroundArray[index % 3] }}
            image={
              employee.background_image.url
                ? employee.background_image.url.replace("http://", "https://")
                : ""
            }
            alt={employee.background_image.alt}
            title={employee.background_image.title}
          />
          <CardContent className={classes.lessPadding}>
            <Typography
              variant="body1"
              color="primary"
              component="h2"
              className={classes.bolder}
            >
              {employee.first_name}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              component="h2"
              className={classes.bolder}
            >
              {employee.last_name}
            </Typography>

            {employee.function ? (
              <Typography
                variant="body2"
                color="primary"
                component="h2"
                className={classes.Discipline}
              >
                {employee.function}
              </Typography>
            ) : (
              <Box height="55px"></Box>
            )}
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <LocationOnIcon color="primary" className={classes.Absolute} />
              <Typography
                variant="body1"
                color="secondary"
                component="h2"
                align="center"
                className={classes.bolder}
              >
                {employee.countryNames && employee.countryNames.length > 0
                  ? employee.countryNames[0]
                  : ""}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}

export default PeopleCard;
