/* polyfills.js */
import "@pwabuilder/pwainstall";
import "@pwabuilder/pwaupdate";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
//
const { store, persistor } = configureStore();
//
//
ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<React.Fragment>loading</React.Fragment>}
      persistor={persistor}
    >
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

/////////////////////////
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
