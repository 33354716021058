import { Avatar, Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import fuzzaldrin from "fuzzaldrin-plus";
import React, { useState } from "react";
import AutoSuggest from "react-autosuggest";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ListItemLink from "../components/ListItemLink";
import logo from "../img/Van-Ameyde-Marine_logo_group.png";

const drawerWidth = 240;
let isApple = ["iPhone", "iPad", "iPod"].includes(navigator.platform);

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const firstNameParts = parse(
    suggestion.first_name,
    match(suggestion.first_name, query)
  );

  const lastNameParts = parse(
    suggestion.last_name,
    match(suggestion.last_name, query)
  );

  const functionParts = parse(
    suggestion.function,
    match(suggestion.function, query)
  );

  let firstName = firstNameParts.map((part) => (
    <span
      key={part.text}
      style={{
        fontWeight: part.highlight ? 500 : 300,
      }}
    >
      {part.text}
    </span>
  ));

  let lastName = lastNameParts.map((part) => (
    <span
      key={part.text}
      style={{
        fontWeight: part.highlight ? 500 : 300,
      }}
    >
      {part.text}
    </span>
  ));

  let functionDesc = functionParts.map((part) => (
    <span
      key={part.text}
      style={{
        fontWeight: part.highlight ? 500 : 300,
      }}
    >
      {part.text}
    </span>
  ));

  return (
    <ListItemLink
      selected={isHighlighted}
      component="div"
      key={suggestion.name}
      href={`/our-people/${suggestion.slug}`}
    >
      <ListItemIcon>
        <Avatar
          style={{ background: "#dbdbdb" }}
          src={
            suggestion.background_image.url
              ? suggestion.background_image.url.replace("http://", "https://")
              : ""
          }
          alt={suggestion.background_image.alt}
          title={suggestion.background_image.title}
          imgProps={{
            style: { objectFit: "contain" },
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <React.Fragment>
            {firstName} {lastName}{" "}
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textSecondary">
              {functionDesc}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItemLink>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarWhite: {
    background: theme.palette.background.paper,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    background: theme.palette.background.paper,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },

  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  cancelButton: {
    color: "#fff",
  },
}));

function NavigationBar({ open, handleDrawerOpen, handleDrawerClose }) {
  let location = useLocation();
  const counterElement = React.useRef(null);

  const tooSmallPhone = useMediaQuery("(max-width:380px)");

  const employees = useSelector((state) => state.employees);
  const favorites = useSelector((state) => state.favorites);

  const { isFetching, items } = employees;

  const lowerCasedCompanies = items.map((company) => {
    return {
      ...company,
      name: `${company.first_name} ${company.last_name} ${company.function}`.toLowerCase(),
    };
  });

  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const { goBack, push } = useHistory();

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showInstallButton, setShowInstallButton] = useState(null);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  React.useEffect(() => {
    if (counterElement && counterElement.current) {
      let installStatus = counterElement.current.getInstalledStatus();

      if (installStatus === false) {
        setShowInstallButton(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterElement]);

  React.useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    if (isApple) {
      setSupportsPWA(true);
    }

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  function getSuggestions(value) {
    return fuzzaldrin
      .filter(lowerCasedCompanies, value, { key: "name" })
      .slice(0, 5);
  }

  let isHomePage = location.pathname === "/";

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  let appTitle = "";
  let showBack = false;
  switch (location.pathname) {
    case "/":
      appTitle = "Home";
      break;
    case "/our-people":
      appTitle = "Our People";
      break;
    case "/our-offices":
      appTitle = "Our Offices";
      break;
    case "/locations":
      appTitle = "Our Offices";
      break;
    case "/emergency-contacts":
      appTitle = "Emergency Contacts";
      break;
    case "/favorites":
      appTitle = "Favorites";
      break;
    case "/desk-consult":
      appTitle = "Desk Consult";
      if (matches === false) {
        showBack = true;
      }
      break;
    default:
      appTitle = "";
  }

  if (location.pathname.includes("/our-people/")) {
    appTitle = "Our People";
    showBack = true;
  }

  if (location.pathname.includes("/our-offices/")) {
    appTitle = "Our Offices";
    showBack = true;
  }

  if (location.pathname.includes("/locations/")) {
    appTitle = "Our Offices";
    showBack = true;
  }

  if (location.pathname.includes("/favorites/")) {
    appTitle = "Favorites";
    showBack = true;
  }

  let hasFavorites = favorites.length > 0;

  return (
    <AppBar
      position="fixed"
      color={isHomePage === false ? "primary" : "inherit"}
      className={clsx(
        classes.appBar,
        {
          [classes.appBarShift]: open && matches === true,
        },
        {
          [classes.appBarWhite]: isHomePage,
        }
      )}
    >
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {(showMobileSearchBar === false || matches === true) && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Hidden smDown>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              {showBack && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={(o) => {
                    if (location.pathname.includes("our-people")) {
                      push("/our-people");
                    } else if (location.pathname.includes("countries")) {
                      push("/locations");
                    } else if (location.pathname.includes("favorites")) {
                      push("/favorites");
                    } else if (location.pathname.includes("desk-consult")) {
                      push("/");
                    } else {
                      goBack();
                    }
                  }}
                  edge="start"
                >
                  <ArrowBackIos />
                </IconButton>
              )}

              {isHomePage === true ? (
                <Box display="flex" flexDirection="column">
                  <img
                    alt="logo"
                    style={{ height: "auto", maxWidth: "50%" }}
                    src={logo}
                    alt="logo"
                  />
                </Box>
              ) : (
                <Typography color="secondary" variant="h6" noWrap>
                  {appTitle}
                </Typography>
              )}
            </Box>
          )}
          {isHomePage === false && (
            <React.Fragment>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>
                  {isFetching === false && (
                    <React.Fragment>
                      {(matches === true || showMobileSearchBar === true) && (
                        <div className={classes.search}>
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>

                          <AutoSuggest
                            suggestions={suggestions}
                            theme={{
                              suggestionsList: classes.suggestionsList,
                              suggestion: classes.suggestion,
                            }}
                            renderSuggestion={renderSuggestion}
                            onSuggestionsClearRequested={() =>
                              setSuggestions([])
                            }
                            onSuggestionsFetchRequested={({ value }) => {
                              setValue(value);
                              setSuggestions(getSuggestions(value));
                            }}
                            onSuggestionSelected={(
                              _,
                              { suggestion, suggestionValue }
                            ) => {
                              push(`/our-people/${suggestion.slug}`);
                              setShowMobileSearchBar(false);
                              setValue("");
                            }}
                            getSuggestionValue={(suggestion) =>
                              `${suggestion.first_name} ${suggestion.last_name}`
                            }
                            inputProps={{
                              classes,
                              id: "react-autosuggest-popper",
                              label: "Zoeken",
                              placeholder: "Zoeken",
                              value: value,

                              onChange: (_, { newValue, method }) => {
                                setValue(newValue);
                              },
                              inputRef: (node) => {
                                setAnchorEl(node);
                              },
                              onKeyDown: (event) => {
                                if (event.keyCode === 13) {
                                  event.preventDefault();
                                  // setSuggestions([]);
                                  // setQuery({ q: event.target.value }, "push");
                                }
                              },
                            }}
                            highlightFirstSuggestion={true}
                            renderInputComponent={(inputProps) => {
                              const {
                                classes,
                                inputRef = () => {},
                                ref,
                                ...other
                              } = inputProps;

                              let newOther = { ...other };
                              newOther.type = "search";

                              return (
                                <InputBase
                                  placeholder="Zoeken naar..."
                                  classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                  }}
                                  autoFocus={showMobileSearchBar}
                                  inputRef={inputRef}
                                  {...newOther}
                                />
                              );
                            }}
                            renderSuggestionsContainer={(options) => {
                              if (matches === true) {
                                return (
                                  <Popper
                                    anchorEl={anchorEl}
                                    open={Boolean(options.children)}
                                    style={{ zIndex: 12000 }}
                                    transition
                                  >
                                    <Box padding="0px 20px">
                                      <Paper
                                        square
                                        {...options.containerProps}
                                        style={{
                                          width: anchorEl
                                            ? anchorEl.clientWidth
                                            : undefined,
                                        }}
                                      >
                                        {options.children}
                                      </Paper>
                                    </Box>
                                  </Popper>
                                );
                              } else {
                                return (
                                  <Box
                                    style={{
                                      top: 56,
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                    }}
                                    position="absolute"
                                  >
                                    <Paper square {...options.containerProps}>
                                      {options.children}
                                    </Paper>
                                  </Box>
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Box>
                {matches === false && isFetching === false && (
                  <React.Fragment>
                    {showMobileSearchBar === false ? (
                      <React.Fragment>
                        {tooSmallPhone && (
                          <IconButton
                            aria-label="Emergency Contacts"
                            edge="end"
                            color="inherit"
                            onClick={() => push("/emergency-contacts")}
                          >
                            <WarningIcon />
                          </IconButton>
                        )}

                        <IconButton
                          aria-label="search"
                          edge="end"
                          color="inherit"
                          onClick={(e) => {
                            setShowMobileSearchBar(true);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </React.Fragment>
                    ) : (
                      <Button
                        onClick={(e) => {
                          setShowMobileSearchBar(false);
                        }}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </Box>
            </React.Fragment>
          )}
          {isHomePage === true && (
            <pwa-install
              ref={counterElement}
              usecustom={isApple === true ? false : true}
              iosinstallinfotext="Tap the share button and then 'Add to Homescreen'"
              descriptionheader="About the app"
              explainer="This app can be installed on your PC or mobile device."
            ></pwa-install>
          )}

          {isHomePage === true &&
            showInstallButton === true &&
            isApple === false &&
            supportsPWA === true && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    counterElement.current.openPrompt();
                  }}
                >
                  Install
                </Button>
              </Box>
            )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
