import { Box, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _filter from "lodash/filter";
import _find from "lodash/find";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import OfficeCard from "../components/OfficeCard";

const selectOfficesByCountryId = createSelector(
  (state) => state,
  (_, countryId) => countryId,
  (state, countryId) => {
    let countryOrRegion = _find(state.countries.items, { slug: countryId });

    if (!countryOrRegion) {
      countryOrRegion = _find(state.regions.items, { slug: countryId });
      return _filter(state.offices.items, function (o) {
        return o.regions.indexOf(Number(countryOrRegion.id)) > -1;
      });
    } else {
      return _filter(state.offices.items, function (o) {
        return o.countries.indexOf(Number(countryOrRegion.id)) > -1;
      });
    }
  }
);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
}));

function OurOffices() {
  const employees = useSelector((state) => state.employees);
  let { countryId } = useParams();

  const offices = useSelector((state) =>
    selectOfficesByCountryId(state, countryId)
  );

  const classes = useStyles();

  if (employees.isFetching) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3}>
        {offices.map((item, i) => {
          return (
            <Grid key={"office" + i} item xs={12} md={6} lg={4}>
              <OfficeCard office={item} />
            </Grid>
          );
        })}
      </Grid>
    </main>
  );
}

export default OurOffices;
