import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import HomeNavigationCard from "../components/HomeNavigationCard";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
    padding: "0px",
  },
  introText: {
    fontWeight: "bold",
  },
  addMargin: {
    marginBottom: "25px",
  },
}));

function Home() {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Container maxWidth="md" style={{ marginTop: "25px" }}>
        <Typography
          variant="h2"
          component="h1"
          align="center"
          className={classes.introText}
          color="primary"
        >
          Whatever it takes 24/7
        </Typography>
        <Typography
          variant="body2"
          component="h2"
          align="center"
          className={classes.addMargin}
          style={{ color: "#777777" }}
        >
          Your network of marine surveyors and consultants. <i>The</i> solution
          for all marine related issues including cargo or hull & machinery
          damage, loss prevention, technical/nautical investigations,
          environmental damage, risk assessments, claim-handling and desk
          consultancy.
        </Typography>
      </Container>
      <Grid container spacing={0} style={{ marginBottom: "56px" }}>
        <Grid item xs={12} md={6} lg={3}>
          <HomeNavigationCard
            backgroundColor={"#00D9B9"}
            textColor={"#27008C"}
            title1={"Our"}
            title2={"People"}
            to="/our-people"
            contentText={"Find the right person for the job"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HomeNavigationCard
            backgroundColor={"#27008C"}
            textColor={"#00D9B9"}
            title1={"Our"}
            title2={"Offices"}
            to="/locations"
            contentText={"Where do you require our assistance"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HomeNavigationCard
            backgroundColor={"#ABABAB"}
            textColor={"#27008C"}
            title1={"Emergency"}
            title2={"Contacts"}
            to="/emergency-contacts"
            contentText={"For a fast response "}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HomeNavigationCard
            backgroundColor={"#00D9B9"}
            textColor={"#27008C"}
            title1={"Desk"}
            title2={"Consult"}
            to="/desk-consult"
            contentText={"On demand technical and nautical expertise"}
          />
        </Grid>
      </Grid>
    </main>
  );
}

export default Home;
