import { Box, CircularProgress, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React from "react";
import { useSelector } from "react-redux";
import CountryCard from "../components/CountryCard";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),

    height: "100%",
  },
  toggleSelected: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${fade(theme.palette.primary.main, 0.12)} !important`,
  },
}));

function Countries() {
  const [alignment, setAlignment] = React.useState("country");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const classes = useStyles();
  const countries = useSelector((state) => state.countries);
  const regions = useSelector((state) => state.regions);
  const { isFetching, items: countryItems } = countries;
  const { items: regionItems } = regions;

  if (isFetching === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" width="100%" justifyContent="center">
            <Paper elevation={0}>
              <StyledToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                size="small"
              >
                <ToggleButton
                  classes={{
                    selected: classes.toggleSelected,
                  }}
                  value="country"
                >
                  Country
                </ToggleButton>
                <ToggleButton
                  classes={{
                    selected: classes.toggleSelected,
                  }}
                  value="region"
                >
                  Region
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Paper>
          </Box>
        </Grid>
        {alignment === "country"
          ? countryItems.map((item, i) => {
              return (
                <Grid
                  key={"country" + i}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <CountryCard country={item} />
                </Grid>
              );
            })
          : regionItems.map((item, i) => {
              return (
                <Grid
                  key={"region" + i}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <CountryCard country={item} />
                </Grid>
              );
            })}
      </Grid>
    </main>
  );
}

export default Countries;
