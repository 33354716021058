import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const employees = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_EMPLOYEES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_EMPLOYEES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.employees,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const countries = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_COUNTRIES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_COUNTRIES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.countries,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const regions = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_REGIONS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_REGIONS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.regions,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const offices = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_OFFICES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_OFFICES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.offices,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const contacts = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_CONTACTS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_CONTACTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.contacts,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const favorites = (state = [], action) => {
  switch (action.type) {
    case types.ADD_FAVORITE:
      return [...state, action.id];
    case types.REMOVE_FAVORITE:
      return state.filter((favoriteId) => favoriteId !== action.id);
    case types.CLEAR_FAVORITES:
      return [];
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  favorites,
  employees,
  countries,
  offices,
  regions,
  contacts,
});

export default rootReducer;
