import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Filter from "@material-ui/icons/FilterList";
import _filter from "lodash/filter";
import _orderBy from "lodash/orderBy";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import OurPeopleFilterDialog from "../components/OurPeopleFilterDialog";
import PeopleCard from "../components/PeopleCard";
import useQuery from "../hooks/useQuery";

const selectEmployeesByOfficeId = createSelector(
  (state) => state,
  (_, query) => query,
  (state, query) => {
    let discipline = query.get("discipline") ? query.get("discipline") : "All";
    let sectors = query.get("sectors") ? query.get("sectors") : "All";

    let sortField = query.get("sortField")
      ? query.get("sortField")
      : "last_name";

    let areas = query.get("areas-of-expertise")
      ? query.get("areas-of-expertise")
      : "All";

    let filteredPeople = _filter(state.employees.items, function (o) {
      let isDiscipline =
        discipline === "All"
          ? true
          : o["discipline"]
          ? o["discipline"].indexOf(discipline) > -1
          : false;
      let isAreas_of_expertise =
        areas === "All"
          ? true
          : o["areas_of_expertise"]
          ? o["areas_of_expertise"].indexOf(areas) > -1
          : false;
      let isSectors =
        sectors === "All"
          ? true
          : o["sectors"]
          ? o["sectors"].indexOf(sectors) > -1
          : false;
      return isDiscipline && isAreas_of_expertise && isSectors;
    });

    let orderedPeople = _orderBy(filteredPeople, [sortField], ["asc"]);

    return orderedPeople;
  }
);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
}));

function OurPeople() {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const push = history.push;
  const classes = useStyles();
  const employees = useSelector((state) => state.employees);
  const { isFetching } = employees;

  const items = useSelector((state) => selectEmployeesByOfficeId(state, query));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isFetching === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  return (
    <React.Fragment>
      <OurPeopleFilterDialog open={open} handleClose={handleClose} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" width="100%" justifyContent="flex-end">
              <Button
                variant="contained"
                color="inherit"
                style={{
                  background: "#fff",
                  position: "fixed",
                  zIndex: "999",
                }}
                startIcon={<Filter color="primary" />}
                onClick={handleClickOpen}
              >
                <Typography variant="inherit" color="primary">
                  Filters
                </Typography>
              </Button>
            </Box>
          </Grid>

          {items.length === 0 && (
            <Grid style={{ height: "100%" }} item xs={12}>
              <Box
                style={{
                  position: "fixed",
                  height: "200px",
                  width: "200px",
                  top: "40%",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignContent="center"
                justifyItems="center"
                alignItems="center"
              >
                <AccountCircleIcon
                  style={{ fontSize: "68px" }}
                  color="secondary"
                  fontSize="inherit"
                />
                <Typography align="center" variant="h5" gutterBottom>
                  No employees found
                </Typography>

                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    push({
                      ...location,
                      search: "",
                    });
                  }}
                >
                  Remove filters
                </Button>
              </Box>
            </Grid>
          )}

          {items.map((item, i) => {
            return (
              <Grid key={"people" + i} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <PeopleCard index={i} employee={item} />
              </Grid>
            );
          })}
        </Grid>
      </main>
    </React.Fragment>
  );
}

export default OurPeople;
