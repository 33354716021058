import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "350px",
    width: "100%",
    margin: "0px auto",
    padding: "15px",
  },

  cardMedia: {
    height: "200px",
    width: "100%",
  },
  CardContainer: {
    backgroundColor: "#F5F5F5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bolder: {
    fontWeight: 700,
    marginTop: "10px",
  },
  Grey: {
    color: "#777777",
    position: "relative",
  },
  InfoContainer: {
    padding: "0px 20px 0px 30px",
    marginTop: "20px",
  },
  Absolute: {
    position: "absolute",
    left: "-30px",
  },
}));

function OfficeCard({ office }) {
  const classes = useStyles();

  const history = useHistory();
  const push = history.push;

  return (
    <React.Fragment>
      <Card
        className={classes.root}
        elevation={2}
        square={true}
        onClick={() =>
          push({
            pathname: `/our-offices/${office.id}`,
          })
        }
      >
        <CardActionArea>
          <CardContent className={classes.CardContainer}>
            {/* <img alt="logo" style={{ height: "64px" }} src={logo} /> */}

            <Typography
              variant="body1"
              color="primary"
              component="h2"
              className={classes.bolder}
            >
              {office.name}
            </Typography>
            <Typography variant="body1" component="h2" className={classes.Grey}>
              {office.label}
            </Typography>
            <div className={classes.InfoContainer}>
              <Typography
                variant="body1"
                component="h2"
                className={classes.Grey}
                style={{ marginBottom: "25px", minHeight: "72px" }}
              >
                <LocationOnIcon
                  color="secondary"
                  className={classes.Absolute}
                />
                {`${office.adress} ${office.town}, ${office.country}`}
              </Typography>
              <Typography
                variant="body1"
                component="h2"
                className={classes.Grey}
                style={{ marginBottom: "10px" }}
              >
                <PhoneIcon color="secondary" className={classes.Absolute} />
                <a href={"tel:" + office.phone}>{office.phone}</a>
              </Typography>
              <Typography
                variant="body1"
                component="h2"
                className={classes.Grey}
              >
                <EmailIcon color="secondary" className={classes.Absolute} />
                <a href={"mailto:" + office.email}>{office.email}</a>
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}

export default OfficeCard;
