import { Box, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _filter from "lodash/filter";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { createSelector } from "reselect";
import PeopleCard from "../components/PeopleCard";

const selectFavoriteEmployees = createSelector(
  (state) => state,
  (_, id) => id,
  (state, id) => {
    let favorites = state.favorites;
    return _filter(state.employees.items, function (o) {
      return favorites.indexOf(o.id) > -1;
    });
  }
);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
}));

function Favorites() {
  const classes = useStyles();
  const history = useHistory();
  const push = history.push;

  const employees = useSelector((state) => state.employees);
  const favorites = useSelector((state) => state.favorites);
  const { isFetching } = employees;
  const items = useSelector((state) => selectFavoriteEmployees(state, ""));

  if (isFetching === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  if (favorites.length === 0) {
    return <Redirect to="/" />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container spacing={3}>
        {items.map((item, i) => {
          return (
            <Grid key={"people" + i} item xs={12} sm={12} md={4} lg={3} xl={2}>
              <PeopleCard index={i} employee={item} origin="favorites" />
            </Grid>
          );
        })}
      </Grid>
    </main>
  );
}

export default Favorites;
