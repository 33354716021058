import { Box, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  gridPadding: {
    padding: "16px",
    paddingBottom: "24px",
    backgroundColor: "#fff",
  },
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#00D7B8",
    justifyContent: "space-between",
    height: "150px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

    height: "100%",
  },
  button: {
    marginBottom: "10px",
    width: "225px;",
  },
  whiteText: {
    color: "#fff",
  },
}));

function CasualtyResponse() {
  const classes = useStyles();

  const contacts = useSelector((state) => state.contacts);

  const { isFetching, items } = contacts;

  if (isFetching === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      </main>
    );
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container spacing={3}>
        <Grid item item xs={12}>
          <Typography
            variant="body2"
            component="h1"
            align="center"
            color="primary"
          >
            For casualty response follow up, contact our offices 24/7 by
            telephone.
          </Typography>
        </Grid>

        {items.map((o) => {
          return (
            <Grid key={o.country} item xs={12} sm={6} md={4} lg={3}>
              <Card square={true} className={classes.gridPadding}>
                <CardContent className={classes.root}>
                  <LocationOnIcon color="primary" />
                  <Typography
                    variant="body1"
                    component="h2"
                    className={classes.whiteText}
                  >
                    {o.country}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    href={`tel:${o.phone_number}`}
                    startIcon={<PhoneIcon color="secondary" />}
                  >
                    {o.phone_number}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </main>
  );
}

export default CasualtyResponse;
