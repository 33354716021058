export const REQUEST_EMPLOYEES = "REQUEST_EMPLOYEES";
export const RECEIVE_EMPLOYEES = "RECEIVE_EMPLOYEES";

export const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";

export const REQUEST_REGIONS = "REQUEST_REGIONS";
export const RECEIVE_REGIONS = "RECEIVE_REGIONS";

export const REQUEST_OFFICES = "REQUEST_OFFICES";
export const RECEIVE_OFFICES = "RECEIVE_OFFICES";

export const REQUEST_CONTACTS = "REQUEST_CONTACTS";
export const RECEIVE_CONTACTS = "RECEIVE_CONTACTS";

export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const CLEAR_FAVORITES = "CLEAR_FAVORITES";
