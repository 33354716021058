import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const persistConfig = {
  key: "vanameyde",
  storage,
  whitelist: ["favorites"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState => {
  let store = createStore(
    persistedReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
  );
  let persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
