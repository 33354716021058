import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%"
  },
  cardMedia: {
    height: "auto",
    width: "100%",
    maxWidth: "49%"
  },
  contactSection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "Column",
    marginBottom: "24px"
  },
  button: {
    marginTop: "10px"
  },
  Accordion: {
    backgroundColor: "#250188"
  },
  NoShadow: {
    boxShadow: "none"
  },
  noBackground: {
    backgroundColor: "#fafafa",
    paddingTop: "0px"
  },
  MoreSlim: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    padding: "25px",
    width: "100%"
  },
  amyedeList: {
    lineHeight: "32px"
  }
}));

// let props = {test: "a" , bla: "x"}

// let {test, bla} = props;

// function Component({test, bla}) {

// }

function DeskConsult() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          <Card square={true}>
            <CardContent className={classes.contactSection}>
              <Typography
                variant="h5"
                color="primary"
                component="h1"
                style={{ fontWeight: "700" }}
              >
                Are you in urgent need of expert advice
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href="mailto:VAMN-DC@ameydemarine.com"
                className={classes.button}
                startIcon={<EmailIcon color="secondary" />}
              >
                Email
              </Button>
              <Button
                variant="contained"
                color="primary"
                href="tel:+31104104400"
                className={classes.button}
                startIcon={<PhoneIcon color="secondary" />}
              >
                Call European timezone
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href="tel:+6563237732"
                startIcon={<PhoneIcon color="secondary" />}
              >
                Call Asia pacific timezone
              </Button>

              <Typography
                variant="body1"
                component="h2"
                style={{ marginTop: "25px" }}
              >
                Are you in urgent need of expert advice on technical and
                nautical issues? Van Ameyde Marine’s Desk Consult is the missing
                link for clients when faced with questions for which their
                in-house expertise does not suffice. <br></br>
                <br></br>Our team is highly experienced in the maritime
                industry, offering:<br></br>
                <ul className={classes.amyedeList}>
                  <li>
                    easy access to technical and nautical know-how and strategic
                    advice
                  </li>
                  <li>immediate response to urgent questions</li>
                  <li>flexibility</li>
                  <li>cost-effectiveness</li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
          <ExpansionPanel
            className={classes.NoShadow}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.Accordion}
            >
              <Typography color="secondary">
                Seamlessly strengthening your teams and in-house expertise from
                second opinion and an expert view on disputes to strategic
                advice
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.noBackground}>
              <CardContent className={classes.MoreSlim}>
                <Typography variant="body1">
                  Are you in need of:
                  <ul className={classes.amyedeList}>
                    <li>a second opinion?</li>
                    <li>
                      technical input to assess and improve your legal position?
                    </li>
                    <li>a quick review of claim amounts or repair costs?</li>
                    <li>
                      assessment of whether a surveyor is to be appointed?
                    </li>
                    <li>assessment of risks involved?</li>
                    <li>
                      an expert view on a dispute (bunkers, performance, cargo
                      operations, stowage, securing)?
                    </li>
                    <li>
                      assistance with interpreting (nautical) data, documents,
                      logs or data loggers?
                    </li>
                    <li>
                      strategic advice, e.g. when your unrecoverable
                      non-deductible is at stake?
                    </li>
                    <li>
                      any advice on issues outside your area of expertise?
                    </li>
                  </ul>
                  We fill any gap in your in-house expertise or staff capacity
                  and aim to seamlessly strengthen your teams.
                </Typography>
              </CardContent>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.NoShadow}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.Accordion}
            >
              <Typography color="secondary">
                For whom? Our Desk Consult clients... from shipping companies
                and terminal operators to insurance brokers and P&I
                correspondents
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.noBackground}>
              <CardContent className={classes.MoreSlim}>
                <Typography variant="body1">
                  Our customers include:
                  <ul className={classes.amyedeList}>
                    <li>shipping companies</li>
                    <li>ship managers | brokers | charterers</li>
                    <li>insurance brokers</li>
                    <li>law firms</li>
                    <li>claims handlers</li>
                    <li>terminal operators</li>
                    <li>logistic partners | freight forwarders</li>
                    <li>storage keepers</li>
                    <li>P&I correspondents</li>
                  </ul>
                </Typography>
              </CardContent>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </main>
  );
}

export default DeskConsult;
